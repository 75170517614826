package com.lightclimb.lightclimbwebpage.styles

import com.lightclimb.lightclimbwebpage.pages.Theme
import com.lightclimb.lightclimbwebpage.utils.Id
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.TransitionProperty
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import org.jetbrains.compose.web.css.ms

val NavigationItemStyle by ComponentStyle {

    cssRule(" > #${Id.navigationText}") {
        Modifier
            .transition(CSSTransition(property = TransitionProperty.All, duration = 300.ms))
            .color(Theme.Black.rgb)
    }
    cssRule(":hover > #${Id.navigationText}") {
        Modifier.color(Theme.ThirdDark.rgb)
    }
}