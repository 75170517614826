package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String
import kotlin.collections.Map

public object ResStrings {
  private val baseLocale: StringsEn = StringsEn

  private val locales: Map<String, Strings> = mapOf("es" to StringsEs, "en" to StringsEn)

  public val home: String
    get() = locales[getCurrentLanguageCode()]?.home ?: baseLocale.home

  public val we: String
    get() = locales[getCurrentLanguageCode()]?.we ?: baseLocale.we

  public val design: String
    get() = locales[getCurrentLanguageCode()]?.design ?: baseLocale.design

  public val program: String
    get() = locales[getCurrentLanguageCode()]?.program ?: baseLocale.program

  public val create: String
    get() = locales[getCurrentLanguageCode()]?.create ?: baseLocale.create

  public val make_it_possible: String
    get() = locales[getCurrentLanguageCode()]?.make_it_possible ?: baseLocale.make_it_possible

  public val home_main: String
    get() = locales[getCurrentLanguageCode()]?.home_main ?: baseLocale.home_main

  public val about_caps: String
    get() = locales[getCurrentLanguageCode()]?.about_caps ?: baseLocale.about_caps

  public val about: String
    get() = locales[getCurrentLanguageCode()]?.about ?: baseLocale.about

  public val about_main_1: String
    get() = locales[getCurrentLanguageCode()]?.about_main_1 ?: baseLocale.about_main_1

  public val about_main_2: String
    get() = locales[getCurrentLanguageCode()]?.about_main_2 ?: baseLocale.about_main_2

  public val about_main_3: String
    get() = locales[getCurrentLanguageCode()]?.about_main_3 ?: baseLocale.about_main_3

  public val methodology: String
    get() = locales[getCurrentLanguageCode()]?.methodology ?: baseLocale.methodology

  public val how_we_do_it: String
    get() = locales[getCurrentLanguageCode()]?.how_we_do_it ?: baseLocale.how_we_do_it

  public val methodology_sub: String
    get() = locales[getCurrentLanguageCode()]?.methodology_sub ?: baseLocale.methodology_sub

  public val methodology_main_1: String
    get() = locales[getCurrentLanguageCode()]?.methodology_main_1 ?: baseLocale.methodology_main_1

  public val methodology_main_2: String
    get() = locales[getCurrentLanguageCode()]?.methodology_main_2 ?: baseLocale.methodology_main_2

  public val methodology_main_3: String
    get() = locales[getCurrentLanguageCode()]?.methodology_main_3 ?: baseLocale.methodology_main_3

  public val our_clients_caps: String
    get() = locales[getCurrentLanguageCode()]?.our_clients_caps ?: baseLocale.our_clients_caps

  public val our_clients: String
    get() = locales[getCurrentLanguageCode()]?.our_clients ?: baseLocale.our_clients

  public val our_clients_temp_1: String
    get() = locales[getCurrentLanguageCode()]?.our_clients_temp_1 ?: baseLocale.our_clients_temp_1

  public val contact_us_caps: String
    get() = locales[getCurrentLanguageCode()]?.contact_us_caps ?: baseLocale.contact_us_caps

  public val contact_us: String
    get() = locales[getCurrentLanguageCode()]?.contact_us ?: baseLocale.contact_us

  public val contact: String
    get() = locales[getCurrentLanguageCode()]?.contact ?: baseLocale.contact

  public val name: String
    get() = locales[getCurrentLanguageCode()]?.name ?: baseLocale.name

  public val last_name: String
    get() = locales[getCurrentLanguageCode()]?.last_name ?: baseLocale.last_name

  public val email: String
    get() = locales[getCurrentLanguageCode()]?.email ?: baseLocale.email

  public val phone_number: String
    get() = locales[getCurrentLanguageCode()]?.phone_number ?: baseLocale.phone_number

  public val business: String
    get() = locales[getCurrentLanguageCode()]?.business ?: baseLocale.business

  public val write_your_message: String
    get() = locales[getCurrentLanguageCode()]?.write_your_message ?: baseLocale.write_your_message

  public val send: String
    get() = locales[getCurrentLanguageCode()]?.send ?: baseLocale.send

  public val contact_send_successfully: String
    get() = locales[getCurrentLanguageCode()]?.contact_send_successfully ?:
        baseLocale.contact_send_successfully

  public val empty_fields_error: String
    get() = locales[getCurrentLanguageCode()]?.empty_fields_error ?: baseLocale.empty_fields_error

  public val unknown_error: String
    get() = locales[getCurrentLanguageCode()]?.unknown_error ?: baseLocale.unknown_error
}
