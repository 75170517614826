package com.lightclimb.lightclimbwebpage.pages

import Res
import androidx.compose.runtime.Composable
import com.lightclimb.lightclimbwebpage.components.PageLayout
import com.lightclimb.lightclimbwebpage.utils.Constants
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.px

@Page
@Composable
fun HomePage() {
    PageLayout {
        HomeScreen()
    }
}

@Composable
fun HomeScreen() {

    val breakpoint = rememberBreakpoint()

    Column(
        modifier = Modifier
            .fillMaxSize(),
        verticalArrangement = if (breakpoint > Breakpoint.MD) Arrangement.Center else Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Row(
            modifier = Modifier.padding(
                top = if (breakpoint > Breakpoint.MD) 0.px else 120.px,
                left = if (breakpoint > Breakpoint.MD) 0.px else 60.px,
            ),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Column(
                modifier = Modifier.padding(
                    left = if (breakpoint > Breakpoint.MD) 40.px else 0.px,
                )
            ) {
                HomeText(Res.string.design, Theme.ThirdDark.rgb)
                HomeText(Res.string.program, Theme.ThirdDark.rgb)
                HomeText(Res.string.create, Theme.SecondDark.rgb)
                HomeText(Res.string.make_it_possible, Theme.Secondary.rgb)
            }
            if (breakpoint > Breakpoint.MD) {
                Column(
                    modifier = Modifier
                        .width(600.px)
                        .padding(left = 90.px)
                        .fontFamily(Constants.LTHOOP)
                        .fontWeight(FontWeight.Light)
                        .color(Theme.Black.rgb)
                ) {
                    SpanText(
                        text = Res.string.home_main
                    )
                }
            }
        }
    }
}


@Composable
fun HomeText(secondText: String, color: CSSColorValue) {
    Row() {
        Column() {
            SpanText(
                text = Res.string.we,
                modifier = Modifier
                    .padding(right = 12.px)
                    .fontSize(40.px)
                    .fontFamily(Constants.UTENDO)
                    .fontWeight(FontWeight.ExtraBold)
                    .color(Theme.Black.rgb)
            )
        }
        Spacer()
        Column() {
            SpanText(
                text = secondText,
                modifier = Modifier
                    .fontSize(40.px)
                    .fontFamily(Constants.UTENDO)
                    .fontWeight(FontWeight.ExtraBold)
                    .color(color)
            )
        }
    }
}
