package com.lightclimb.lightclimbwebpage.pages

import androidx.compose.runtime.Composable
import com.lightclimb.lightclimbwebpage.components.PageLayout
import com.lightclimb.lightclimbwebpage.utils.Constants
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.px


@Page
@Composable
fun AboutUsPage() {
    AboutUsScreen()
}

@Composable
fun AboutUsScreen() {

    val breakpoint = rememberBreakpoint()

    Box(
        modifier = Modifier.fillMaxSize(),
        contentAlignment = Alignment.Center
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .backgroundColor(Theme.Primary.rgb)
        ) {


            PageLayout {

                Column(
                    modifier = Modifier
                        .fillMaxSize(),
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {

                    Row(
                        modifier = Modifier.padding(
                            left = 20.px,
                        ),
                        verticalAlignment = Alignment.CenterVertically,
                    ) {
                        if (breakpoint > Breakpoint.MD) {
                            Column(
                                modifier = Modifier
                                    .width(420.px)
                                    .padding(left = 10.px)
                                    .fontFamily(Constants.UTENDO)
                                    .fontWeight(FontWeight.Bold)
                                    .color(Theme.Secondary.rgb)
                            ) {
                                SpanText(
                                    text = Res.string.about_caps,
                                    modifier = Modifier.fontSize(90.px)
                                )
                            }
                        }
                        Column(
                            modifier = Modifier
                                .width(if (breakpoint > Breakpoint.MD) 600.px else 350.px)
                                .padding(left = if (breakpoint > Breakpoint.MD) 100.px else 0.px)
                                .fontFamily(Constants.UTENDO)
                                .fontWeight(FontWeight.Light)
                                .color(Theme.Black.rgb)
                        ) {
                            if (breakpoint < Breakpoint.MD) {

                                SpanText(
                                    text = Res.string.about_caps,
                                    modifier = Modifier.fontSize(60.px)
                                        .fontFamily(Constants.UTENDO)
                                        .fontWeight(FontWeight.Bold)
                                        .color(Theme.Secondary.rgb)
                                )
                            }

                            SpanText(
                                text = Res.string.about_main_1,
                                modifier = Modifier.padding(
                                    bottom = 30.px,
                                    top = if (breakpoint < Breakpoint.MD) 20.px else 0.px
                                )
                            )
                            SpanText(
                                text = Res.string.about_main_2,
                                modifier = Modifier.padding(bottom = 30.px)

                            )

                            SpanText(
                                text = Res.string.about_main_3
                            )
                        }
                    }
                }
            }
        }
    }
}
