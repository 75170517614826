package com.lightclimb.lightclimbwebpage.pages

import androidx.compose.runtime.Composable
import com.lightclimb.lightclimbwebpage.components.PageLayout
import com.lightclimb.lightclimbwebpage.utils.Constants
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.px

@Page
@Composable
fun MethodologyPage() {
    MethodologyScreen()
}

@Composable
fun MethodologyScreen() {

    val breakpoint = rememberBreakpoint()

    Box(
        modifier = Modifier.fillMaxSize(),
        contentAlignment = Alignment.Center
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .backgroundColor(Theme.Primary.rgb)
        ) {

            PageLayout {
                Box() {
                    Column(
                        modifier = Modifier
                            .fillMaxSize(),
                        verticalArrangement = Arrangement.Top,
                        horizontalAlignment = Alignment.Start
                    ) {


                        Row(
                            modifier = Modifier.padding(
                                top = if (breakpoint > Breakpoint.MD) 200.px else 80.px,
                                left = if (breakpoint > Breakpoint.MD) 120.px else 20.px
                            ),
                            verticalAlignment = Alignment.Top,
                        ) {
                            SpanText(
                                text = Res.string.how_we_do_it, modifier = Modifier
                                    .fontSize(45.px)
                                    .fontFamily(Constants.UTENDO)
                                    .fontWeight(FontWeight.Bold)
                                    .color(Theme.Secondary.rgb)
                            )
                        }

                        Row(
                            modifier = Modifier.padding(
                                top = 50.px,
                                left = if (breakpoint > Breakpoint.MD) 120.px else 20.px
                            ).width(if (breakpoint > Breakpoint.MD) 1400.px else 370.px),
                            verticalAlignment = Alignment.Top,
                        ) {
                            SpanText(
                                text = Res.string.methodology_sub,
                                modifier = Modifier
                                    .fontFamily(Constants.UTENDO)
                                    .fontWeight(FontWeight.Light)
                                    .color(Theme.Black.rgb)
                            )
                        }

                        if (breakpoint > Breakpoint.MD) {

                            Row(
                                modifier = Modifier
                                    .padding(
                                        top = 80.px,
                                        left = if (breakpoint > Breakpoint.MD) 70.px else 20.px
                                    )
                                    .fontFamily(Constants.UTENDO)
                                    .fontWeight(FontWeight.Light)
                                    .color(Theme.Black.rgb),
                                verticalAlignment = Alignment.Top,
                            ) {
                                SpanText(
                                    text = Res.string.methodology_main_1,
                                    modifier = Modifier
                                        .margin(left = 40.px)
                                        .padding(
                                            left = 25.px,
                                            top = 25.px,
                                            right = 20.px,
                                            bottom = 25.px
                                        )
                                        .width(390.px)
                                        .backgroundColor(Color.white)
                                        .borderRadius(28.px)
                                )
                                SpanText(
                                    text = Res.string.methodology_main_2,
                                    modifier = Modifier
                                        .margin(left = 50.px)
                                        .padding(
                                            left = 25.px,
                                            top = 25.px,
                                            right = 20.px,
                                            bottom = 25.px
                                        ).width(390.px)
                                        .backgroundColor(Color.white)
                                        .borderRadius(28.px)
                                )
                                SpanText(
                                    text = Res.string.methodology_main_3,
                                    modifier = Modifier
                                        .margin(left = 50.px)
                                        .padding(
                                            left = 25.px,
                                            top = 25.px,
                                            right = 20.px,
                                            bottom = 25.px
                                        )
                                        .width(390.px)
                                        .backgroundColor(Color.white)
                                        .borderRadius(28.px)
                                )
                            }
                        } else {
                            Column(
                                modifier = Modifier
                                    .padding(
                                        top = 20.px,
                                        left = 20.px
                                    )
                                    .fontFamily(Constants.UTENDO)
                                    .fontWeight(FontWeight.Light)
                                    .color(Theme.Black.rgb),
                            ) {
                                SpanText(
                                    text = Res.string.methodology_main_1,
                                    modifier = Modifier
                                        .width(390.px)
                                        .margin(right = 10.px)
                                        .padding(
                                            top = 24.px
                                        )
                                )
                                SpanText(
                                    text = Res.string.methodology_main_2,
                                    modifier = Modifier
                                        .width(390.px)
                                        .margin(right = 10.px)
                                        .padding(
                                            top = 24.px
                                        )
                                )

                                SpanText(
                                    text = Res.string.methodology_main_3,
                                    modifier = Modifier
                                        .width(390.px)
                                        .margin(right = 10.px)
                                        .padding(
                                            top = 24.px,
                                            bottom = 34.px
                                        )
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}
