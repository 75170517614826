package com.lightclimb.lightclimbwebpage.pages

import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.rgb
import org.jetbrains.compose.web.css.rgba

enum class Theme(
    val hex: String,
    val rgb: CSSColorValue
) {
    Primary(
        hex = "#000022",
        rgb = rgb(0, 0, 34)
    ),
    Secondary(
        hex = "#015560",
        rgb = rgb(1, 85, 96)
    ),

    SecondDark(
        hex = "#017D8D",
        rgb = rgb(2, 125, 141)
    ),

    ThirdDark(
        hex = "#01A2B8",
        rgb = rgb(1, 162, 184)
    ),

    FourthDark(
        hex = "#A4FFF4",
        rgb = rgb(164, 255, 250)
    ),

    Black(
        hex = "#011F23",
        rgb = rgb(1, 31, 35)
    ),

    Gray(
        hex = "#C4C3C3",
        rgb = rgb(196, 195, 195)
    ),

    HalfBlack(
        hex = "#000000",
        rgb = rgba(0, 0, 0, 0.5)
    ),

    HalfWhite(
        hex = "#99FFFF",
        rgb = rgba(153, 255, 255, 0.6)
    ),

    HalfSecondary(
        hex = "#015560",
        rgb = rgba(1, 85, 96, 0.6)
    ),

}