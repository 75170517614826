package com.lightclimb.lightclimbwebpage

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.core.App
import com.varabyte.kobweb.silk.SilkApp
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.style.common.SmoothColorStyle
import com.varabyte.kobweb.silk.components.style.toModifier
import io.github.skeptick.libres.LibresSettings
import kotlinx.browser.localStorage
import kotlinx.browser.window
import org.jetbrains.compose.web.css.vh


const val LOCALE_KEY = "kobweblocalisation:locale"
@App
@Composable
fun AppEntry(content: @Composable () -> Unit) {

    LibresSettings.languageCode =
        (localStorage.getItem(LOCALE_KEY)
            ?: Res.locales.find { it == window.navigator.language }
            ?: Res.locales.first())
            .also { localStorage.setItem(LOCALE_KEY, it) }

    SilkApp {
        Surface(SmoothColorStyle.toModifier().minHeight(100.vh)) {
            content()
        }
    }
}
