package com.lightclimb.lightclimbwebpage.utils

import com.lightclimb.lightclimbwebpage.models.Contact
import com.varabyte.kobweb.browser.api
import kotlinx.browser.window
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

suspend fun saveContact(contact: Contact): Boolean {
    window.api.logOnError = true
    return try {
        window.api.tryPost(
            apiPath = "savecontact",
            body = Json.encodeToString(contact).encodeToByteArray()
        )?.decodeToString().toBoolean()
    } catch (e: Exception) {
        println(e.message)
        false
    }
}