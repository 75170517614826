package com.lightclimb.lightclimbwebpage.components

import Res
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.lightclimb.lightclimbwebpage.LOCALE_KEY
import com.lightclimb.lightclimbwebpage.navigation.Screen
import com.lightclimb.lightclimbwebpage.pages.Theme
import com.lightclimb.lightclimbwebpage.styles.NavigationItemStyle
import com.lightclimb.lightclimbwebpage.utils.Constants.PAGE_WIDTH
import com.lightclimb.lightclimbwebpage.utils.Constants.TOP_PANEL_HEIGHT
import com.lightclimb.lightclimbwebpage.utils.Constants.UTENDO
import com.lightclimb.lightclimbwebpage.utils.Id.navigationText
import com.lightclimb.lightclimbwebpage.utils.Resources
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.scrollBehavior
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.modifiers.translateX
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaBars
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh


@Composable
fun TopPanel(onMenuClick: () -> Unit) {
    val breakpoint = rememberBreakpoint()
    if (breakpoint > Breakpoint.MD) {
        TopPanelInternal()
    } else {
        CollapsedSidePanel(onMenuClick = onMenuClick)
    }
}

@Composable
fun TopPanelInternal() {

    Row(
        modifier = Modifier
            .height(TOP_PANEL_HEIGHT.px)
            .width(PAGE_WIDTH.px)
            .position(Position.Fixed)
            .zIndex(9)
            .backgroundColor(color = Color.transparent),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Column(
        ) {
            Row {
                Image(
                    modifier = Modifier.margin(right = 122.px)
                        .padding(left = 80.px)
                        .backgroundColor(color = Color.transparent),
                    src = Resources.Image.logo,
                    description = "Logo Image"
                )
                NavigationItems(false)
            }
            Box(
                modifier = Modifier
                    .margin(top = 30.px)
                    .height(3.px)
                    .width(1510.px)
                    .backgroundColor(Theme.Gray.rgb)
                    .color(Theme.Gray.rgb)
            )
        }

    }
}

@Composable
fun NavigationItems(overflowed: Boolean) {
    val context = rememberPageContext()
    var languageSelected by remember { mutableStateOf(false) }

    NavigationItem(
        title = Res.string.home,
        selected = context.route.path == Screen.Home.route,
        overflowed = overflowed
    ) {
        context.router.navigateTo(Screen.Home.route)
    }
    NavigationItem(
        title = Res.string.about,
        selected = context.route.path == Screen.AboutUs.route,
        overflowed = overflowed
    ) {
        context.router.navigateTo(Screen.AboutUs.route)
    }
    NavigationItem(
        title = Res.string.methodology,
        selected = context.route.path == Screen.Methodology.route,
        overflowed = overflowed
    ) {
        context.router.navigateTo(Screen.Methodology.route)
    }
    NavigationItem(
        title = Res.string.our_clients,
        selected = context.route.path == Screen.OurClients.route,
        overflowed = overflowed
    ) {
        context.router.navigateTo(Screen.OurClients.route)
    }
    NavigationItem(
        title = Res.string.contact_us,
        selected = context.route.path == Screen.ContactUs.route,
        overflowed = overflowed,
    ) {
        context.router.navigateTo(Screen.ContactUs.route)
    }


    Image(
        modifier = Modifier.margin(right = 122.px, top = 12.px)
            .padding(left =  if (overflowed) 0.px else 10.px, right = if (overflowed) 10.px else 0.px)
            .backgroundColor(color = Color.transparent)
            .height(34.px)
            .width(42.px)
            .onClick {
                languageSelected = !languageSelected
                if (localStorage.getItem(LOCALE_KEY) == "en") {
                    localStorage.setItem(LOCALE_KEY, "es")
                    window.location.reload()
                } else {
                    localStorage.setItem(LOCALE_KEY, "en")
                    window.location.reload()
                }
            },
        src = if (localStorage.getItem(LOCALE_KEY) == "en") Resources.Image.spainflag else Resources.Image.usflag,
        description = "Change Lenguage"
    )

    //  NavigationItem(
    //      title = "Leng",
    //      selected = languageSelected,
    //      overflowed = overflowed
    //  ) {
//
    //  }
}


@Composable
fun NavigationItem(
    modifier: Modifier = Modifier,
    selected: Boolean = false,
    overflowed: Boolean = false,
    title: String,
    onClick: () -> Unit
) {

    Row(
        modifier = NavigationItemStyle.toModifier()
            .then(modifier)
            .cursor(Cursor.Pointer)
            .onClick { onClick() },
        verticalAlignment = Alignment.CenterVertically
    ) {
        SpanText(
            text = title,
            modifier = modifier
                .id(navigationText)
                .fontFamily(UTENDO)
                .fontWeight(if (selected) FontWeight.Bold else FontWeight.Light)
                .margin(
                    right = if (overflowed) 0.px
                    else if (localStorage.getItem(LOCALE_KEY) == "es") 89.px
                    else 105.px,
                    bottom = if (overflowed) 40.px else 0.px,
                    top = if (overflowed) 0.px else 20.px
                )
                .fontSize(if (overflowed) 14.px else 18.px)
                .thenIf(
                    condition = selected,
                    other = Modifier.color(Theme.ThirdDark.rgb)
                )

        )
    }

}

@Composable
private fun CollapsedSidePanel(onMenuClick: () -> Unit) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .height(TOP_PANEL_HEIGHT.px)
            .padding(leftRight = 24.px)
            .backgroundColor(Color.white),
        verticalAlignment = Alignment.CenterVertically
    ) {
        FaBars(
            modifier = Modifier
                .margin(right = 24.px)
                .color(Theme.ThirdDark.rgb)
                .cursor(Cursor.Pointer)
                .onClick { onMenuClick() },
            size = IconSize.XL
        )
        Image(
            modifier = Modifier.width(120.px).height(120.px),
            src = Resources.Image.logo,
            alt = "Logo Image"
        )
    }
}

@Composable
fun OverflowSidePanel(
    onMenuClose: () -> Unit,
    // content: @Composable () -> Unit
) {
    val scope = rememberCoroutineScope()
    val breakpoint = rememberBreakpoint()

    var translateX by remember { mutableStateOf((-100).percent) }
    var opacity by remember { mutableStateOf(0.percent) }

    LaunchedEffect(key1 = breakpoint) {
        translateX = 0.percent
        opacity = 100.percent
        if (breakpoint > Breakpoint.MD) {
            scope.launch {
                translateX = (-100).percent
                opacity = 0.percent
                delay(500)
                onMenuClose()
            }
        }
    }

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .height(100.vh)
            .position(Position.Fixed)
            .zIndex(9)
            .opacity(opacity)
            .transition(CSSTransition(property = "opacity", duration = 300.ms))
            .backgroundColor(Theme.HalfBlack.rgb)
    ) {
        Column(
            modifier = Modifier
                .padding(all = 24.px)
                .fillMaxHeight()
                .width(if (breakpoint < Breakpoint.MD) 30.percent else 25.percent)
                .translateX(translateX)
                .transition(CSSTransition(property = "translate", duration = 300.ms))
                .overflow(Overflow.Auto)
                .scrollBehavior(ScrollBehavior.Smooth)
                .backgroundColor(Color.white)
        ) {
            Row(
                modifier = Modifier.margin(bottom = 60.px, top = 24.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Image(
                    modifier = Modifier
                        .margin(right = 20.px)
                        .width(60.px).height(60.px)
                        .onClick {
                            scope.launch {
                                translateX = (-100).percent
                                opacity = 0.percent
                                delay(500)
                                onMenuClose()
                            }
                        }
                        .cursor(Cursor.Pointer),
                    src = Resources.Image.logoShort,
                    alt = "Logo Image"
                )
                //  FaXmark(
                //      modifier = Modifier
                //          .color(Theme.ThirdDark.rgb)
                //          .cursor(Cursor.Pointer)
                //          .onClick {
                //              scope.launch {
                //                  translateX = (-100).percent
                //                  opacity = 0.percent
                //                  delay(500)
                //                  onMenuClose()
                //              }
                //          },
                //      size = IconSize.LG
                //  )

            }
            NavigationItems(true)
        }
    }
}
