package com.lightclimb.lightclimbwebpage.utils

object Constants {
    const val UTENDO= "Utendo"
    const val LTHOOP= "Martian Mono"
    const val TOP_PANEL_HEIGHT = 140
    const val PAGE_WIDTH = 1920
}

object Resources {
    object Image {
        const val logo = "/logowhite.svg"
        const val logoShort = "/logoshort.svg"
        const val spainflag = "/spainflag.png"
        const val usflag = "/usflag.png"
    }
}

object Id {
    const val navigationText = "navigationText"
    const val nameInput = "nameInput"
    const val lastNameInput = "lastNameInput"
    const val emailInput = "emailInput"
    const val phoneInput = "phoneInput"
    const val businessInput = "businessInput"
    const val messageInput = "messageInput"


}
