package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String

public object StringsEn : Strings {
  override val home: String = "Home"

  override val we: String = "WE"

  override val design: String = "DESIGN"

  override val program: String = "PROGRAM"

  override val create: String = "CREATE"

  override val make_it_possible: String = "MAKE IT POSSIBLE"

  override val home_main: String =
      "Welcome to LightClimb Solutions! We’re a dynamic team of passionate creators specializing in mobile app development and sleek web design. Our mission? To empower small businesses like yours with cutting-edge digital solutions."

  override val about_caps: String = "ABOUT"

  override val about: String = "About"

  override val about_main_1: String =
      "At LightClimb Solutions, we thrive on speed. Our agile approach ensures rapid development cycles, allowing us to transform your ideas into functional apps and websites in record time. Whether it’s an intuitive mobile app or a minimalist web page, we’ve got you covered!"

  override val about_main_2: String =
      "Less is more, and we embrace minimalism. Our designs are sleek, clutter-free, and optimized for seamless user experiences. We believe that simplicity enhances functionality, making your brand stand out in a crowded digital landscape."

  override val about_main_3: String =
      "As a small business ourselves, we understand your unique needs. That’s why we work closely with you to tailor our solutions to fit your vision. From wireframes to launch, we’re your dedicated partners every step of the way."

  override val methodology: String = "Methodology"

  override val how_we_do_it: String = "HOW WE DO IT"

  override val methodology_sub: String =
      "Here at LightClimb Solutions, we develop all our projects using the Kotlin programming language, leveraging its multiplatform capabilities to achieve fast and efficient results. We’ve found that it is a game-changer. Here is why:"

  override val methodology_main_1: String =
      "Instead of hiring separate developers for Android, iOS, Web, and Backend, we maintain a single team proficient in Kotlin. This streamlines communication, ensures consistent quality across platforms, and most importantly, reduces costs."

  override val methodology_main_2: String =
      "Time is of the essence, especially for small businesses. KMP (Kotlin Multiplatform) allows our developers to switch hats seamlessly. Whether it’s coding for Android, iOS, or Web, they can collaborate effectively. This agility accelerates our development cycles, getting developments into user's hands faster."

  override val methodology_main_3: String =
      "Kotlin is the language of the future. It enjoys robust support and a massive, ever-growing community worldwide. It feels like having thousands of developers at your service, guaranteeing that anyone could take over the code down the line and continue maintaining it. No more migrations or legacy code. Your development is set to last a lifetime!"

  override val our_clients_caps: String = "OUR CLIENTS"

  override val our_clients: String = "Our Clients"

  override val our_clients_temp_1: String =
      "Let’s grow together! With more than 8 years of mobile development experience behind the scenes, LightClimb Solutions is ready to bring your vision to life, let us prove what we can do for you!"

  override val contact_us_caps: String = "CONTACT US"

  override val contact_us: String = "Contact Us"

  override val contact: String = "Contact"

  override val name: String = "Name"

  override val last_name: String = "Last Name"

  override val email: String = "Email"

  override val phone_number: String = "Phone Number"

  override val business: String = "Business"

  override val write_your_message: String = "Write your message"

  override val send: String = "Send"

  override val contact_send_successfully: String =
      "Contact information received, we will be reaching out soon!"

  override val empty_fields_error: String = "Input fields are empty!"

  override val unknown_error: String =
      "Something happened, please try again later or reach us at our email!"
}
