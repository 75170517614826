package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String

public object StringsEs : Strings {
  override val home: String = "Inicio"

  override val we: String = "ACA"

  override val design: String = "DISEÑAMOS"

  override val program: String = "PROGRAMAMOS"

  override val create: String = "CREAMOS"

  override val make_it_possible: String = "LO HACEMOS POSIBLE"

  override val home_main: String =
      "¡Bienvenidos a LightClimb! Somos un equipo dinámico de creadores apasionados especializados en el desarrollo de aplicaciones móviles y diseño web elegante. ¿Nuestra misión? Empoderar a pequeñas empresas como la tuya con soluciones digitales de vanguardia."

  override val about_caps: String = "ACERCA DE"

  override val about: String = "Acerca de"

  override val about_main_1: String =
      "En LightClimb, nos movemos rápido. Nuestro enfoque ágil garantiza ciclos de desarrollo rápidos, lo que nos permite transformar tus ideas en aplicaciones y sitios web funcionales en tiempo récord. Ya sea una aplicación móvil intuitiva o una página web minimalista, estamos aquí para ayudarte."

  override val about_main_2: String =
      "Menos es más, y nosotros abrazamos el minimalismo. Nuestros diseños son elegantes, sin desorden y optimizados para experiencias de usuario fluidas. Creemos que la simplicidad mejora la funcionalidad, haciendo que tu marca destaque en un mundo digital saturado."

  override val about_main_3: String =
      "Como pequeña empresa, entendemos tus necesidades únicas. Por eso trabajamos estrechamente contigo para adaptar nuestras soluciones a tu visión. Desde los bocetos hasta el lanzamiento, somos tus socios dedicados en cada paso del camino."

  override val methodology: String = "Metodología"

  override val how_we_do_it: String = "COMO LO HACEMOS"

  override val methodology_sub: String =
      "Aquí en LightClimb, desarrollamos todos nuestros proyectos utilizando el lenguaje de programación Kotlin, aprovechando sus capacidades multiplataforma para lograr resultados rápidos y eficientes. Es la herramienta perfecta:"

  override val methodology_main_1: String =
      "En lugar de contratar desarrolladores distintos para Android, iOS, Web y Backend, mantenemos un único equipo experto en Kotlin. Esto agiliza la comunicación, garantiza una calidad consistente en todas las plataformas y, lo más importante, reduce los masivamente los costos."

  override val methodology_main_2: String =
      "El tiempo es esencial, especialmente para las pequeñas empresas. KMP (Kotlin Multiplatform) permite a nuestros desarrolladores cambiar de roles sin problemas. Ya sea programando para Android, iOS o Web, pueden colaborar de manera efectiva. Esta agilidad acelera nuestros ciclos de desarrollo, poniendo los productos en manos de los usuarios de manera más rápida."

  override val methodology_main_3: String =
      "Kotlin es el lenguaje del futuro. Goza de un sólido soporte y una comunidad masiva en constante crecimiento en todo el mundo. Se siente como tener miles de desarrolladores a su servicio, garantizando que cualquiera pueda hacerse cargo del código en el futuro y seguir manteniéndolo. No más migraciones o código heredado. ¡Su desarrollo está destinado a durar toda la vida!"

  override val our_clients_caps: String = "NUESTROS CLIENTES"

  override val our_clients: String = "Nuestros Clientes"

  override val our_clients_temp_1: String =
      "¡Crezcamos juntos! Con más de 8 años de trayectoria personal como profesional en desarrollo móvil, LightClimb Solutions está listo para hacer realidad tu visión, déjanos demostrar lo que podemos hacer por ti."

  override val contact_us_caps: String = "CONTACTANOS"

  override val contact_us: String = "Contáctanos"

  override val contact: String = "Contacto"

  override val name: String = "Nombre"

  override val last_name: String = "Apellido"

  override val email: String = "Correo electrónico"

  override val phone_number: String = "Teléfono"

  override val business: String = "Empresa"

  override val write_your_message: String = "Escribe tu mensaje"

  override val send: String = "Enviar"

  override val contact_send_successfully: String = "Datos recibidos, te contactaremos pronto!"

  override val empty_fields_error: String = "Algunos campos están vacios!"

  override val unknown_error: String =
      "Algo ocurrió, inténtalo de nuevo más tarde o contáctanos por correo!"
}
