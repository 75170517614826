package com.lightclimb.lightclimbwebpage.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.lightclimb.lightclimbwebpage.components.PageLayout
import com.lightclimb.lightclimbwebpage.models.Contact
import com.lightclimb.lightclimbwebpage.styles.ContactUsPanelStyle
import com.lightclimb.lightclimbwebpage.utils.Constants
import com.lightclimb.lightclimbwebpage.utils.Id
import com.lightclimb.lightclimbwebpage.utils.saveContact
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Resize
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.outline
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.resize
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.vw
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.TextArea
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLTextAreaElement

@Page
@Composable
fun ContactUsPage() {
    ContactUsScreen()
}

@Composable
fun ContactUsScreen() {


    val scope = rememberCoroutineScope()
    var warningMessage by remember { mutableStateOf("") }
    val breakpoint = rememberBreakpoint()


    Box(
        modifier = Modifier.fillMaxSize(),
        contentAlignment = Alignment.Center
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .backgroundColor(Theme.Primary.rgb)
        ) {

            PageLayout {

                Column(
                    modifier = Modifier
                        .fillMaxSize()
                        .zIndex(8),
                    verticalArrangement = Arrangement.Top,
                    horizontalAlignment = Alignment.Start
                ) {

                    Row(
                        modifier = Modifier.padding(
                            top = if (breakpoint > Breakpoint.MD) 180.px else 30.px,
                            left = if (breakpoint > Breakpoint.MD) 120.px else 55.px
                        ),
                        verticalAlignment = Alignment.Top,
                    ) {
                        SpanText(
                            text = Res.string.contact_us_caps,
                            modifier = Modifier
                                .fontSize(45.px)
                                .fontFamily(Constants.UTENDO)
                                .fontWeight(FontWeight.Bold)
                                .color(Theme.Secondary.rgb)
                        )
                    }

                    if (breakpoint < Breakpoint.MD) {
                        SpanText(
                            text = "lightclimbsolutions@gmail.com",
                            modifier = Modifier
                                .padding(top = 8.px, left = 58.px)
                                .fontFamily(Constants.UTENDO)
                                .fontWeight(FontWeight.Light)
                                .color(Theme.Black.rgb)
                        )
                    }
                    Row(
                        modifier = Modifier.padding(
                            top = if (breakpoint > Breakpoint.MD) 40.px else 0.px,
                            left = if (breakpoint > Breakpoint.MD) 250.px else 32.px,
                            right = if (breakpoint > Breakpoint.MD) 240.px else 0.px
                        ),
                        verticalAlignment = Alignment.Top,
                        horizontalArrangement = Arrangement.Center,
                    ) {
                        Column(
                            modifier = Modifier
                                .backgroundColor(Color.transparent)
                                .width(if (breakpoint > Breakpoint.MD) 800.px else 330.px)
                                .height(if (breakpoint > Breakpoint.MD) 450.px else 580.px)
                        ) {
                            Row() {
                                Input(
                                    type = InputType.Text,
                                    attrs = ContactUsPanelStyle.toModifier()
                                        .id(Id.nameInput)
                                        .width(if (breakpoint > Breakpoint.MD) 300.px else 276.px)
                                        .height(48.px)
                                        .margin(
                                            top = 38.px,
                                            left = if (breakpoint > Breakpoint.MD) 60.px else 30.px
                                        )
                                        .padding(leftRight = 20.px)
                                        .backgroundColor(Color.white)
                                        .borderRadius(r = 28.px)
                                        .fontSize(16.px)
                                        .fontFamily(Constants.UTENDO)
                                        .fontWeight(FontWeight.Light)
                                        .noBorder()
                                        .toAttrs {
                                            attr("placeholder", Res.string.name)
                                        }
                                )
                                if (breakpoint > Breakpoint.MD) {
                                    Input(
                                        type = InputType.Text,
                                        attrs = ContactUsPanelStyle.toModifier()
                                            .id(Id.lastNameInput)
                                            .width(300.px)
                                            .height(48.px)
                                            .margin(top = 38.px, left = 80.px, right = 60.px)
                                            .padding(leftRight = 20.px)
                                            .backgroundColor(Color.white)
                                            .borderRadius(r = 28.px)
                                            .fontSize(16.px)
                                            .fontFamily(Constants.UTENDO)
                                            .fontWeight(FontWeight.Light)
                                            .noBorder()
                                            .toAttrs {
                                                attr("placeholder", Res.string.last_name)
                                            }
                                    )
                                }
                            }

                            if (breakpoint < Breakpoint.MD) {
                                Row() {
                                    Input(
                                        type = InputType.Text,
                                        attrs = ContactUsPanelStyle.toModifier()
                                            .id(Id.lastNameInput)
                                            .width(276.px)
                                            .height(48.px)
                                            .margin(
                                                top = 20.px,
                                                left = 30.px,
                                            )
                                            .padding(leftRight = 20.px)
                                            .backgroundColor(Color.white)
                                            .borderRadius(r = 28.px)
                                            .fontSize(16.px)
                                            .fontFamily(Constants.UTENDO)
                                            .fontWeight(FontWeight.Light)
                                            .noBorder()
                                            .toAttrs {
                                                attr("placeholder", Res.string.last_name)
                                            }
                                    )
                                }
                            }
                            Row(
                            ) {
                                Input(
                                    type = InputType.Email,
                                    attrs = ContactUsPanelStyle.toModifier()
                                        .id(Id.emailInput)
                                        .width(if (breakpoint > Breakpoint.MD) 680.px else 276.px)
                                        .height(48.px)
                                        .margin(
                                            top = 20.px,
                                            left = if (breakpoint > Breakpoint.MD) 60.px else 30.px
                                        )
                                        .padding(leftRight = 20.px)
                                        .backgroundColor(Color.white)
                                        .borderRadius(r = 28.px)
                                        .fontSize(16.px)
                                        .fontFamily(Constants.UTENDO)
                                        .fontWeight(FontWeight.Light)
                                        .noBorder()
                                        .toAttrs {
                                            attr("placeholder", Res.string.email)
                                        }
                                )
                            }
                            Row() {
                                Input(
                                    type = InputType.Text,
                                    attrs = ContactUsPanelStyle.toModifier()
                                        .id(Id.phoneInput)
                                        .width(if (breakpoint > Breakpoint.MD) 300.px else 276.px)
                                        .height(48.px)
                                        .margin(
                                            top = 20.px,
                                            left = if (breakpoint > Breakpoint.MD) 60.px else 30.px
                                        )
                                        .padding(leftRight = 20.px)
                                        .backgroundColor(Color.white)
                                        .borderRadius(r = 28.px)
                                        .fontSize(16.px)
                                        .fontFamily(Constants.UTENDO)
                                        .fontWeight(FontWeight.Light)
                                        .noBorder()
                                        .toAttrs {
                                            attr("placeholder", Res.string.phone_number)
                                        }
                                )
                                if (breakpoint > Breakpoint.MD) {
                                    Input(
                                        type = InputType.Text,
                                        attrs = ContactUsPanelStyle.toModifier()
                                            .id(Id.businessInput)
                                            .width(300.px)
                                            .height(48.px)
                                            .margin(top = 20.px, left = 80.px, right = 60.px)
                                            .padding(leftRight = 20.px)
                                            .backgroundColor(Color.white)
                                            .borderRadius(r = 28.px)
                                            .fontSize(16.px)
                                            .fontFamily(Constants.UTENDO)
                                            .fontWeight(FontWeight.Light)
                                            .noBorder()
                                            .toAttrs {
                                                attr("placeholder", Res.string.business)
                                            }
                                    )
                                }
                            }
                            if (breakpoint < Breakpoint.MD) {
                                Input(
                                    type = InputType.Text,
                                    attrs = ContactUsPanelStyle.toModifier()
                                        .id(Id.businessInput)
                                        .width(276.px)
                                        .height(48.px)
                                        .margin(top = 20.px, left = 30.px)
                                        .padding(leftRight = 20.px)
                                        .backgroundColor(Color.white)
                                        .borderRadius(r = 28.px)
                                        .fontSize(16.px)
                                        .fontFamily(Constants.UTENDO)
                                        .fontWeight(FontWeight.Light)
                                        .noBorder()
                                        .toAttrs {
                                            attr("placeholder", Res.string.business)
                                        }
                                )
                            }
                            Row() {
                                TextArea(
                                    attrs = ContactUsPanelStyle.toModifier()
                                        .id(Id.messageInput)
                                        .width(if (breakpoint > Breakpoint.MD) 680.px else 276.px)
                                        .height(if (breakpoint > Breakpoint.MD) 130.px else 100.px)
                                        .resize(Resize.None)
                                        .margin(
                                            top = 20.px,
                                            left = if (breakpoint > Breakpoint.MD) 60.px else 30.px
                                        )
                                        .padding(
                                            leftRight = 20.px,
                                            top = 20.px
                                        )

                                        .backgroundColor(Color.white)
                                        .borderRadius(r = 28.px)
                                        .fontSize(16.px)
                                        .fontFamily(Constants.UTENDO)
                                        .fontWeight(FontWeight.Light)
                                        .noBorder()
                                        .toAttrs {
                                            attr("placeholder", Res.string.write_your_message)
                                        }
                                )
                            }

                            Row() {
                                if (breakpoint > Breakpoint.MD) {
                                    SpanText(
                                        modifier = Modifier.width(220.px)
                                            .padding(
                                                left = 64.px,
                                                top = 24.px
                                            )
                                            .color(Colors.DarkRed)
                                            .textAlign(TextAlign.Start)
                                            .fontFamily(Constants.UTENDO)
                                            .fontWeight(FontWeight.Bold),
                                        text = ""
                                    )
                                }
                                Button(
                                    attrs = Modifier
                                        .width(150.px)
                                        .height(48.px)
                                        .zIndex(8)
                                        .margin(
                                            top = 20.px,
                                            left = if (breakpoint > Breakpoint.MD) 372.px else 31.px
                                        )
                                        .backgroundColor(Theme.Secondary.rgb)
                                        .color(Colors.White)
                                        .borderRadius(r = 28.px)
                                        .fontFamily(Constants.UTENDO)
                                        .fontWeight(FontWeight.Light)
                                        .cursor(Cursor.Pointer)
                                        .border(
                                            width = 0.px,
                                            style = LineStyle.None,
                                            color = Color.transparent
                                        )
                                        .outline(
                                            width = 0.px,
                                            style = LineStyle.None,
                                            color = Color.transparent
                                        )
                                        .fontSize(16.px)
                                        .onClick {
                                            scope.launch {
                                                try {
                                                    val name =
                                                        (document.getElementById(Id.nameInput) as HTMLInputElement).value
                                                    val lastname =
                                                        (document.getElementById(Id.lastNameInput) as HTMLInputElement).value
                                                    val email =
                                                        (document.getElementById(Id.emailInput) as HTMLInputElement).value
                                                    val phone =
                                                        (document.getElementById(Id.phoneInput) as HTMLInputElement).value
                                                    val business =
                                                        (document.getElementById(Id.businessInput) as HTMLInputElement).value
                                                    val message =
                                                        (document.getElementById(Id.messageInput) as HTMLTextAreaElement).value


                                                    if (name.isNotEmpty() &&
                                                        lastname.isNotEmpty() &&
                                                        email.isNotEmpty() &&
                                                        phone.isNotEmpty() &&
                                                        business.isNotEmpty() &&
                                                        message.isNotEmpty()
                                                    ) {
                                                        try {
                                                        saveContact(
                                                            contact = Contact(
                                                                name,
                                                                lastname,
                                                                email,
                                                                phone,
                                                                business,
                                                                message
                                                            ))
                                                               warningMessage =
                                                                   Res.string.contact_send_successfully
                                                               delay(5000)
                                                               warningMessage = ""

                                                               (document.getElementById(Id.nameInput) as HTMLInputElement).value = ""
                                                               (document.getElementById(Id.lastNameInput) as HTMLInputElement).value = ""
                                                               (document.getElementById(Id.emailInput) as HTMLInputElement).value = ""
                                                               (document.getElementById(Id.phoneInput) as HTMLInputElement).value = ""
                                                               (document.getElementById(Id.businessInput) as HTMLInputElement).value = ""
                                                               (document.getElementById(Id.messageInput) as HTMLTextAreaElement).value = ""
                                                        }catch (e:Exception){

                                                        }
                                                    } else {
                                                        warningMessage =
                                                            Res.string.empty_fields_error
                                                        delay(3000)
                                                        warningMessage = ""
                                                    }
                                                } catch (e: Exception) {
                                                    warningMessage = Res.string.unknown_error
                                                    delay(3000)
                                                    warningMessage = ""
                                                }
                                            }
                                        }
                                        .toAttrs()
                                ) {
                                    SpanText(text = Res.string.send)
                                }
                            }
                        }
                        if (breakpoint > Breakpoint.MD) {
                            Column {
                                SpanText(
                                    text = Res.string.contact,
                                    modifier = Modifier
                                        .padding(top = 38.px)
                                        .fontFamily(Constants.UTENDO)
                                        .fontWeight(FontWeight.Bold)
                                        .color(Theme.Black.rgb)
                                        .fontSize(20.px)
                                )
                                SpanText(
                                    text = "lightclimbsolutions@gmail.com",
                                    modifier = Modifier
                                        .padding(top = 8.px)
                                        .fontFamily(Constants.UTENDO)
                                        .fontWeight(FontWeight.Light)
                                        .color(Theme.Black.rgb)
                                )
                            }
                        }
                    }
                }
            }
        }
        if (warningMessage.isNotEmpty()) {
            MessagePopup(warningMessage) {
                warningMessage = ""
            }
            scope.launch {
                delay(5000)
                warningMessage = ""
            }
        }
    }
}

fun Modifier.noBorder(): Modifier {
    return this.outline(
        width = 0.px,
        style = LineStyle.None,
        color = Colors.Transparent
    )
}


@Composable
fun MessagePopup(
    message: String,
    onDialogDismiss: () -> Unit
) {
    Box(
        modifier = Modifier
            .width(100.vw)
            .height(100.vh)
            .position(Position.Fixed)
            .zIndex(19),
        contentAlignment = Alignment.Center
    ) {
        Box(
            modifier = Modifier
                .fillMaxSize()
                .backgroundColor(Theme.HalfBlack.rgb)
                .onClick { onDialogDismiss() }
        )
        Box(
            modifier = Modifier
                .padding(all = 24.px)
                .backgroundColor(Colors.White)
                .borderRadius(r = 4.px)
        ) {
            SpanText(
                modifier = Modifier
                    .fillMaxWidth()
                    .textAlign(TextAlign.Center)
                    .fontFamily(Constants.UTENDO)
                    .fontWeight(FontWeight.Bold)
                    .color(Theme.Secondary.rgb)
                    .fontSize(16.px),
                text = message
            )
        }
    }
}

