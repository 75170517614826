package com.lightclimb.lightclimbwebpage.models

import kotlinx.serialization.Serializable

@Serializable
data class Contact(
    val _id: String = "",
    val name: String = "",
    val email: String = "",
    val phone: String = "",
    val business: String = "",
    val message: String = ""
)